<script setup>
import MobileBlogHeaderTabs from './partials/mobile-blog-header-tabs';
import MobileBlogHeaderMenus from './partials/mobile-blog-header-menus';
import MobileBlogHeaderCard from './partials/mobile-blog-header-card';
import MobileBlogCard from './partials/mobile-blog-card';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  meta: {
    type: Object,
    required: true
  },
  fetchData: {
    type: Function,
    required: true,
  },
});

const page = ref(1);
const pageData = ref(props.data);
const scrollPosition = ref(0);
const isAtBottom = ref(false);
const isLoading = ref(false);
const endOfPage = ref(false);

const route = useRoute();
const router = useRouter();

const metaStore = useMetaStore();
const meta = ref({});
metaStore.getMetaTags()

meta.value = metaStore.metaGetter();
metaStore.setMeta(props.meta);

const checkScroll = () => {
  scrollPosition.value = window.scrollY;
  isAtBottom.value = window.innerHeight + window.scrollY >= document.body.offsetHeight;
}

onMounted(async () => {
  window.addEventListener('scroll', checkScroll);
})

watch(() => props.data, () => pageData.value = props.data);

watch(isAtBottom, async () => {
  if (isLoading.value || endOfPage.value) {
    return;
  }
  isLoading.value = true;
  // Update query
  await router.push({
    query: { ...route.query, page: ++page.value },
  });

  // Fetch and update new page data
  const { data } = await props.fetchData();
  if (data.posts.length) {
    pageData.value.posts.push(...data.posts);
  }else {
    endOfPage.value = true;
  }
  isLoading.value = false;
});
</script>
<template>
  <LayoutAppHead></LayoutAppHead>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.blog')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>
    <mobile-blog-header-tabs></mobile-blog-header-tabs>
    <div class="my-3 px-4">
      <mobile-blog-header-menus :country_id="pageData.country_id"></mobile-blog-header-menus>
      <mobile-blog-header-card v-if="pageData?.posts?.length" :data="pageData.posts[0]"></mobile-blog-header-card>
      <div class="flex flex-col item-center mt-2" v-if="pageData?.posts?.length">
        <div class="relative mb-4">
          <div class="my-2" v-for="blog in pageData?.posts?.slice(1)">
            <mobile-blog-card :data="blog"></mobile-blog-card>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute -bottom-5 left-1/2 -translate-x-1/2">
      <span class="loader" v-if="isLoading"></span>
    </div>
    <template #mobile-footer></template>
  </NuxtLayout>
</template>